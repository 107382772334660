.Contact .map_contact {
    width: 100%;
    height: 450px;
    opacity: 0.7;
}

.Contact .contact_form {
    font-size: 24px;
    font-family: "Poppins-SemiBold";
}

.Contact .crd_contact {
    border: 1px solid #d3d3d32b;
    background-color: #F9F9F9;
    box-shadow: 0px 5px 7px #00000029;
    padding: 1.5rem;
    border-radius: 3px;
}

.Contact .crd_img {
    width: 30px;
    height: 30px;
}

.Contact .crd_headng {
    font-size: 18px;
    color: #58360B;
    font-family: "Poppins-SemiBold";
    margin-left: 0.75rem;
    margin-bottom: 0.5rem;
}

.Contact .toll_free {
    font-size: 14px;
    font-family: "Poppins-Medium";
}

.Contact .lght-text {
    color: grey;
}

.Contact .inpt_fld {
    width: 100%;
    padding: 1rem 1.4rem;
    border: 1px solid #D4D4D4;
    border-radius: 5px;
    margin-bottom: 1rem;
    resize: none;
}

.Contact .inpt_fld:focus {
    outline: none;
}

.Contact .px-send {
    padding-left: 4rem;
    padding-right: 4rem;
}
