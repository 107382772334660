.MyAccount p{
    color: #58360B;
}

.MyAccount h2{
    font-size: 21px;
    font-weight: 600;
    color: #58360B;
}

/* my account / profile */
.MyAccount .profile_heading {
    font-size: 18px;
    font-family: "Poppins-SemiBold";
    color: #58360B;
}

.MyAccount .gry_div_prof{
    background-color: #F8F8F8;
    padding: 1.5rem;
}

.MyAccount .brdr-btm-line{
    border-bottom: 2px solid #70707063;
    width: 100%;
    margin-bottom: 25px;
}

.MyAccount .facebook_div{
    border: 1px solid #707070;   
    padding: 1.5rem; 
}

.MyAccount .fb-btn{
    background: #9DACCC;
    color: #fff;
    padding: 8px 44px;
    font-size: 13px;
}

/* profile tab */
.ProfileTabs_content {
    font-family: "Poppins-SemiBold";
    font-size: 16px;
    color: #58360B;
}

.ProfileTabs_content .brdr-right-brwn {
    border-left: 3px solid #58360B;
}

.ProfileTabs_content .prof_txt {
    margin-bottom: 0;
}

