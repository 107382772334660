.AboutUs .who_are_we {
    font-size: 24px;
    font-weight: 600;
    font-family: "Poppins-SemiBold";
}

.AboutUs .abt-cent-mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.AboutUs .mt-cus-about {
    margin-top: 10rem;
    /* margin-bottom: 10rem; */
}

.AboutUs .mt-abtz-cus2 {
    margin-top: 8rem;
}

.AboutUs .main-para {
    font-family: 'Source Serif 4 14pt';
    font-size: 20px;
    color: #000D2A;
    font-weight: 500;
}

.AboutUs .featrd_plns {
    font-size: 11px;
    font-family: "Poppins-SemiBold";
    color: #58360B;
}

.cooking-book {
    background-image: url('../Media/about/bookBg.png');
    background-repeat: no-repeat;
    background-size: cover;

}

.about_book {
    position: absolute;
    top: -60px;
    left: 10%;
    object-fit: cover;
    width: 440px !important;
}

.cooking-book .book-innerCont {
    width: 100%;
    overflow: hidden;
}

.cooking-book .book-innerCont img {
    width: 100%;
}

.cooking-book .title {
    font-family: 'Source Serif 4 14pt';
    font-size: 34px;
    color: #fff;
    font-weight: 500;
    float: right;
}

.cooking-book .small-title {
    font-family: 'Source Serif 4 14pt';
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    /* float: right; */
    text-align: right;
}

.cooking-book .small-para {
    font-family: 'Source Serif 4 14pt';
    font-size: 12px;
    color: #ffffffba;
    /* float: right; */
    text-align: right;
    line-height: 2;
}

.cooking-book .view-btn {
    background-color: #ffff;
    padding: 9px 44px;
    float: right;
    text-transform: uppercase;
    border-radius: 0px;
}

.cooking-book .right-content {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/* .slider .crd_select_meals{
    padding: 1.5rem;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px black;
}

.slider .Slider_top_custom_arrows-next{
    background-image: url('../Media/rightarrow.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60px;
    width: 60px;
    position: absolute;
    right: -9%;
    top: 0%;
}

.slider .Slider_top_custom_arrows{
    background-image: url('../Media/leftarrrow.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60px;
    width: 60px;
    position: absolute;
    left: -9%;
} */

/* .slick-list, .slick-slider, .slick-track {
    position: relative;
    display: contents !important;
} */

.AboutUs .menuhead {
    font-size: 24px;
    font-family: "Poppins-SemiBold";

}

.AboutUs .crd_menu {
    border-radius: 8px;
    box-shadow: 0px 0px 6px 1px #00000030;
    padding: 1rem;
    background: #fff;
}

.AboutUs .menu_crd_hd {
    font-size: 16px;
    /* font-weight: 700;   */
    margin: 1.25rem 0;
    font-family: "Poppins-SemiBold";

}

.AboutUs .menu_dr-p {
    font-size: 12px;
    color: #002833;
    font-family: 'Source Serif 4 14pt';
}

.AboutUs .menu_dr-p2 {
    font-size: 16px;
    color: #58360B;
    font-family: 'Source Serif 4 14pt';
}

.AboutUs .menu_dr-p2 span {
    font-size: 16px;
    color: #949494;
    font-family: 'Source Serif 4 14pt';
    margin-left: 10px;
}

/* .AboutUs .slider-card{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #fff;
    padding: 22px 20px;
}

.AboutUs .slider-card .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AboutUs .slider-card .content .title{
    font-size: 17px;
    font-family: 'Source Serif 4 14pt';
    color: #002833;
    margin: 20px 0px;
}

.AboutUs .slider-card .content .tags{
    background-color: #DED0B6;
    padding: 4px 13px;
    font-family: 'Source Serif 4 14pt';
    font-size: 14px;
}

.AboutUs .slider-card .details{
    font-size: 14px;
    font-family: 'Source Serif 4 14pt';
    color: #002833;
}

.AboutUs .slider-card .price{
    font-size: 33px;
    color: #58360B;
    font-family: 'Source Serif 4 14pt';
    font-weight: bold;
    margin: 0;
}

.AboutUs .slider-card .Mealbtn{
    background-color: #58360B;
    color: #ffff;
    font-family: 'Source Serif 4 14pt';
    margin: 0;
    width: 100%;
    padding: 10px;
    margin: 10px 0px;
}

.AboutUs .slick-slide {
    margin: 0px 8px;
}

.AboutUs .slick-track {
    height: 82vh !important;
}

.AboutUs .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 82vh !important;
} */

/* slider zaam */
.AboutUs .Slider_top_custom_arrows-about-prev {
    height: 60px;
    width: 60px;
    background-image: url('../Media/leftarrrow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 210px;
    left: -100px;
    cursor: pointer;
}

.AboutUs .Slider_top_custom_arrows-about-next {
    height: 60px;
    width: 60px;
    background-image: url('../Media/rightarrow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 210px;
    right: -100px;
    cursor: pointer;
}

.AboutUs .slick-list {
    max-width: 1120px !important;
}

.AboutUs .box_flx_about_container {
    position: relative;
}

/* .AboutUs .box_flx_about_slider{
    margin-left:1rem ;
    margin-right:1rem ;
} */

.AboutUs .slick-slide>div:first-child {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 1rem 0;
}

@media only screen and (max-width: 1150px) {
    .AboutUs .slick-list {
        max-width: 930px !important;
    }

    .AboutUs .Slider_top_custom_arrows-about-prev {
        left: -70px;
    }

    .AboutUs .Slider_top_custom_arrows-about-next {
        right: -70px;
    }

    .whatsmenu .menu_dr-p {
        font-size: 10px;
    }
}


@media only screen and (max-width: 992px) {
    .AboutUs .slick-list {
        max-width: 673px !important;
    }

    .AboutUs .slick-slide img {
        max-width: 100%;
        height: 350px;
        object-fit: cover;
    }

    .AboutUs .Slider_top_custom_arrows-about-prev {
        left: -40px;
        height: 40px;
        width: 40px;
    }

    .AboutUs .Slider_top_custom_arrows-about-next {
        right: -40px;
        height: 40px;
        width: 40px;
    }

    .cooking-book {
        background-position: right;
    }

    .cooking-book .right-content {
        min-height: 580px;
        margin-top: 9rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (max-width: 992px) {
    .TopFooterCollage .flx-2-cont {
        width: 103%;
    }
}

@media only screen and (max-width: 480px) {
    .TopFooterCollage .flx-2-cont {
        width: 103%;
    }

    .AboutUs .main-para {
        font-size: 13px;
    }

    .AboutUs .mt-cus-about {
        margin-top: 2rem;
    }

    .AboutUs .who_are_we {
        font-size: 20px;
    }

    .AboutUs .mt-abtz-cus2 {
        margin-top: 1rem;
    }

    .AboutUs .slick-list {
        max-width: 290px !important;
    }

    .AboutUs .Slider_top_custom_arrows-about-next {
        display: none;
    }

    .AboutUs .Slider_top_custom_arrows-about-prev {
        display: none;
    }

    .AboutUs .mt-rez-aboutus {
        margin-top: 1.5rem !important;
    }

    .AboutUs .px-rezz-about {
        padding: 0 1rem !important;
    }

    .about_book {
        display: none;
    }

    .cooking-book .right-content {
        margin-top: 2rem;
    }
}