.OurRecipes {
    margin: 4rem 0;
}

.OurRecipes .time_real {
    font-size: 24px;
    font-family: "Poppins-SemiBold";
}

.OurRecipes .rec_desscc {
    font-size: 12px;
    font-family: "Poppins-Medium";
}

.OurRecipes_content .line_right {
    position: absolute;
    height: 75%;
    border-right: 1px solid lightgray;
    right: 0;
    top: 15%;
}

/* recepie filter comp */
.RecepieFilters .flx-advncsrch {
    display: flex;
}

.RecepieFilters {
    border-right: 2px solid lightgray;
}

.RecepieFilters .justify-center {
    justify-content: flex-start;
}

.RecepieFilters .header_search_fld {
    width: 75%;
    border: 0;
    background-color: #ECEEEF;
    padding: 0.3rem 1.5rem;
}

.RecepieFilters .header_search_fld:focus {
    outline: none;
}

.RecepieFilters .brwn_bx {
    background: #58360B;
    color: white;
    align-self: center;
    padding: 0.3rem 0.5rem;
}

.RecepieFilters .advnc_srch {
    font-family: "Poppins-Regular";
    font-size: 9px;
}

.RecepieFilters .brwn_line {
    width: 52%;
    border-bottom: 1px solid brown;
    position: relative;
    top: -22px;
    margin-right: 1rem;
}

.RecepieFilters .ul_pad_zero {
    padding-left: 0;
}

.RecepieFilters .ul_tick_round_white {
    background: url('../Media/filter_img.png');
    background-position: 0;
    background-repeat: no-repeat;
    list-style: none;
    margin: 16px 0 0;
    padding-left: 25px;
    color: grey;
}

.RecepieFilters .txt-cap {
    text-transform: uppercase;
    font-family: "Poppins-Regular";
    font-size: 11px;
}

.RecepieFilters .filter_head {
    font-size: 18px;
    font-family: "Poppins-SemiBold";
    margin-bottom: 2rem;
}

.RecepieFilters .recent_recepie_div {
    color: #ADADAD;
    border-bottom: 1px solid #ADADAD;
    margin-top: 1rem;
}

.RecepieFilters .img_recentrecepie {
    width: 70px;
    height: 50px;
    object-fit: cover;
}

.RecepieFilters .recepie_name {
    margin-bottom: 0;
    font-size: 15px;
    font-family: "Poppins-SemiBold";
}

.RecepieFilters .recepie_desc {
    font-family: "Poppins-Light";
    font-size: 11px;
}

.RecepieFilters .recepie_by {
    font-size: 11px;
    font-family: "Poppins-Regular";
}

.RecepieFilters .img_popreci {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    object-fit: cover;
}

.RecepieFilters .star_style {
    color: #58360B;
    font-size: 21px;
}

.brdr-btm-no {
    border-bottom: unset !important;
}

@media only screen and (max-width: 1150px) {
    .RecepieFilters .brwn_line {
        width: 38%;
    }
    .RecepieFilters .recepie_name{
        font-size: 13px;
    }
    .RecepieFilters .star_style{
        font-size: 16px;
    }
}