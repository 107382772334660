@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:ital,opsz,wght@0,8..60,300;0,8..60,400;0,8..60,500;0,8..60,600;0,8..60,700;0,8..60,800;0,8..60,900;1,8..60,400;1,8..60,500;1,8..60,600;1,8..60,700;1,8..60,800;1,8..60,900&display=swap');
*{
    font-family: 'Source Serif 4' !important;
}
.HomePage {
    background-image: url("../Media/commonbg.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
}


.HomeBannerPage .bannerImage {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    width: 100%;
    height: 100%;
    color: white;
}

.HomeBannerPage .clr-trxsprt{
    color: transparent!important;
    opacity: 0;
}

.HomeBannerPage .HomeBannerHeading {
    font-size: 86px;
    font-family: "Poppins-Regular";
    font-weight: 500;
}

.HomeBannerPage .HomeBannerSubHeading {
    font-size: 20px;
    font-family: "Poppins-SemiBold";
    text-transform: uppercase;
}

.HomeBannerPage .ptpb-cus {
    padding: 7rem 0;
}

.HomeBannerPage .img-homebann-exp {
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: center;
}

.HomeBannerPage .crd-bdy-exp-mainhead {
    font-size: 20px;
    font-family: "Poppins-Medium";
}

.HomeBannerPage .crd-bdy-exp-subhead {
    font-size: 14px;
    color: #CCCBC0;
    font-family: "Poppins-Medium";

}

.HomeBannerPage .crd-bdy-exp-Exploe {
    font-size: 16px;
    font-family: "Poppins-Medium";
    text-transform: uppercase;

}

.HomeBannerPage .crd-bdy-exp {
    background-color: #58360B;
    color: white;
    align-items: center;
    padding: 2rem 0 1.5rem 0;
    height: 200px;
}

.HomeBannerPage .share_txt {
    font-size: 44px;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
}

.HomeBannerPage .bx-share_txt {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.HomeBannerPage .my-exp-cuss {
    margin: 5rem 0;
}

.DietCoach .flx-itemz-diet {
    display: flex;
}

.DietCoach .flx-dietz-frst {
    flex: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* height: 100%; */
    width: 100%;
}

.DietCoach .flx-dietz-sec {
    flex: 1;
    padding: 9rem 0rem;
}

.DietCoach .mx-wdth-dietcoch {
    max-width: 500px;
    padding-left: 3rem;
    /* margin: auto; */
    box-sizing: border-box;
}

.DietCoach .diet_mainHead {
    font-size: 42px;
    color: #002533;
    font-family: "Poppins-Semibold";
    font-weight: 600;
}
.DietCoach .ml-pln {
    font-size: 14px;
    font-family: "Poppins-Medium";
    text-transform: uppercase;
    font-weight: 500;
}

.DietCoach .ml-pln {
    font-size: 14px;
    font-family: "Poppins-Medium";

}

.DietCoach .ml-txtt {
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins-Regular";
    color: #ffffffc2!important;
}

.DietCoach .what_would_head {
    font-size: 36px;
    margin-bottom: 0;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
}

.DietCoach .cookTodayCrd {
    padding: 10rem 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.DietCoach .cookTodayCrd .lunchDinnBtmn a {
    color: #fff;
}
.DietCoach .cookTodayCrd .lunchDinnBtmn:hover a{
    color:#000;
}
.DietCoach .homeDietLstImg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.DietCoach .pl-homeDietLstimg{
    padding-left: 10rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 3rem;
}

.DietCoach .home2zzz{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.DietCoach .home_img_brwnn{
    background-image: url('../Media/home/home_img_brwnn.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.DietCoach .subs_two {
    font-size: 45px;
    margin-top: 5rem;
    font-family: "Poppins-SemiBold";
    font-weight: 500;
}

.DietCoach .subs_txt_two {
    font-size: 16px;
    font-family: "Poppins-Regular";
    font-weight: 400;
    color: #ffffffc9;
}

.DietCoach .subs_txt_two_fld {
    padding: 0.7rem 1.3rem;
    width: 66%;
}

.DietCoach .subs_txt_two_fld:focus {
    outline: none;
}

.LatestVideo .Latest_Videos_heading {
    font-size: 41px;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
}


.LatestVideo .img-col-3s{
    width: 100%;
    height: 249px;
    object-fit: cover;
}

.LatestVideo .brwn_crd-info{
    background-color: #58360B;
    color: white;
    padding: 37px 10px !important;
}

.LatestVideo .comp_guidee{
    font-size: 20px;
    font-family: "Poppins-Medium";

}

.LatestVideo .over100{
    font-size: 14px;
    color: #CCCBC0;
    font-family: "Poppins-Regular";
}

.LatestVideo .nutirtnWrkshp{
    background-image: url('../Media/latst_downSec.png');
    background-position: center;
    background-size: 85% 100%;
    background-repeat: no-repeat;
    /* height: 100%; */
    width: 100%;
    background-position: left;
}

.LatestVideo .ptmt-nutrin{
    margin: 8rem 0;
    /* padding: 8rem 0; */
}

.LatestVideo .nut_heading {
    font-size: 41px;
    font-family: "Poppins-SemiBold";
    font-weight: 500;
}

.whatsmenu{
    margin-bottom: 1rem;
}
.whatsmenu p.del {
    color: #E23E00;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

.LatestVideo .nut_subheading{
    font-size: 16px;
    color: #707070;
    font-family: "Poppins-Medium";

}

.LatestVideo .nut_txt{
    font-size: 14px;
    font-family: "Poppins-Regular";
    color: #707070;
    font-weight: 600;
    opacity: 0.7;
}

.LatestVideo .nutrnt_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-right: 3rem;
}
.whatsmenu p.menu_dr-p2 span {
    text-decoration: line-through;
}
.DietCoach .cookTodayCrd::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, #050505, #172c3600, #00798b00, #00b87800, #a8eb1200);
    top: 0px;
}
.LatestVideo .d-inline-block{
    width: fit-content;
}

.whatsmenu .menuhead {
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    font-weight: 600;
}

.ptmt-nutrin .img-wrapper {
    overflow: hidden;
}

.ptmt-nutrin .img-wrapper img {
    transition: all 0.4s ease-in-out;
}
.DietCoach .cookTodayCrd::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, #050505, #172c3600, #00798b00, #00b87800, #a8eb1200);
    top: 0px;
}
.whatsmenu p.menu_dr-p2 span {text-decoration: line-through;}

.whatsmenu p.del {
    color: #E23E00;
    font-size: 18px;
    font-weight: 600;
}

.ptmt-nutrin .img-wrapper img:hover {
    transform: scale(1.1);
}
.ptmt-nutrin .img-wrapper figure{
    margin: 0px;
}
.ptmt-nutrin .img-wrapper img{
    transition: all 0.3s ease-in-out;
}
.nutirtnWrkshp.ptmt-nutrin .img-wrapper {
    overflow: hidden;
}
.whatsmenu .crd_menu{
    border-radius: 8px;
    box-shadow: 0px 0px 6px 1px #00000030;
    padding: 1rem;
    background: #fff;
}
.whatsmenu .crd_menu img {
    width: 100%;
    height: 150px;
}
.whatsmenu .menu_crd_hd{
    font-size: 16px;    
    font-weight: 600;
    margin: 1.25rem 0;
    font-family: "Poppins-SemiBold";

}

.whatsmenu .menu_dr-p{
    font-size: 12px;
    font-weight: 600;
    color: #002833;
    font-family: 'Source Serif 4 14pt';
}

.whatsmenu .menu_dr-p2{
    font-size: 16px;
    font-weight: 500;
    color: #58360B;
    font-family: 'Source Serif 4 14pt';
}

.whatsmenu .menu_dr-p2 span{
    font-size: 16px;
    color: #949494;
    font-family: 'Source Serif 4 14pt';
    margin-left: 10px;
}

/* responsive start */

@media only screen and (max-width: 1150px){
    .HomeBannerPage .HomeBannerHeading{
        font-size: 42px;
    }
    .HomeBannerPage .HomeBannerSubHeading{
        font-size: 16px;    
    }
    .HomeBannerPage .img-homebann-exp{
        margin-top: 1rem;
    }
    .DietCoach .subs_two{
        font-size: 25px;
    }
    .LatestVideo .comp_guidee{
        font-size: 16px;
    }
    .TopFooterCollage .font_Photogallery_subhead{
        font-size: 20px;
    }
    .TopFooterCollage{
        max-width: 98.7vw;
    }
    .DietCoach .homeDietLstImg{
        max-width: 98.7vw;     
    }
}

@media only screen and (max-width: 480px){
    .HomeBannerPage .share_txt{
        font-size: 20px;
    }
    .HomeBannerPage .ptpb-cus{
        padding: 7rem 1rem;
    }
    .DietCoach .pl-homeDietLstimg{
        padding-left: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-right: 2rem;
    }
    .DietCoach .what_would_head{
        font-size: 18px;
    }
    .DietCoach .flx-itemz-diet{
        flex-direction: column;
    }
    .DietCoach .diet_mainHead{
       font-size: 22px; 
    }
    .DietCoach .mx-wdth-dietcoch{
        padding-left: 1.5rem;
    }
    .LatestVideo .nutrnt_content{
        padding-right: 0;
    }
    .LatestVideo .nutirtnWrkshp{
        background-size: 100% 100%;
        padding: 1rem;
    }
    .LatestVideo .nut_heading{
        font-size: 24px;
    }
    .LatestVideo .ptmt-nutrin{
        margin: 2rem 0;
    }
    .LatestVideo .d-inline-block{
        margin-bottom: 1rem;
    }
    .LatestVideo .rez-mrgn-ltst{
        margin: 1rem 0 !important;
    }
}
