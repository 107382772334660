.Footer {
    background: #101010;
    color: white;
}

.Footer .cat_big_txt {
    font-size: 15px;
    margin-bottom: 0.5rem;
    font-family: "Poppins-Regular";
}

.Footer .cat_sm_txt {
    font-size: 13px;
    font-family: "Poppins-Regular";

}

.Footer .cat_head_txt {
    font-size: 22px;
    font-family: "Poppins-SemiBold";
}

.Footer .mt-copy {
    margin-top: 5rem;
}

.Footer .copyRight{
    font-size: 15px;
    font-family: "Poppins-Regular";
}

/* //TopFooterCollag// */

.TopFooterCollage {
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
    /* margin-top: 3rem; */
}

.TopFooterCollage .gall_img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 265px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 1rem;
    color: white;
    align-items: center;
    font-size: 20px;
}

.TopFooterCollage  .absDiv{
    display: block;
    background: linear-gradient(100deg, rgba(63, 63, 63, 0.8) 0%, rgba(255, 255, 255, 0) 25%);
    z-index: -1;
}

/* .TopFooterCollage .gall_img:hover .absDiv{
    display: block;
} */

.TopFooterCollage .flx-1-cont {
    /* background-image: url('../Media/ftr1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%; */
    width: 100%;
    flex: 1;
}

.TopFooterCollage .flx-3-cont {
    /* background-image: url('../Media/ftr2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    width: 100%;
    flex: 1;
}

.TopFooterCollage .flx-2-cont{
    background-color: #DED0B6;
    padding: 12rem 0;
    color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.TopFooterCollage .bt-fit{
    /* display: inline-block; */
    width: fit-content;
    align-self: center;
}

.TopFooterCollage .font_Photogallery_head{
    font-size: 17px;
    font-family: "Poppins-SemiBold";
}

.TopFooterCollage .font_Photogallery_subhead {
    font-size: 32px;
    font-family: "Poppins-SemiBold";
    color: #634B2C;
    position: relative;
    left: -3px;
}

.footer-logo{
    width: 90%;
}



@media only screen and (max-width: 992px) {
    .TopFooterCollage {
        flex-direction: column;
    }
    .footer .gall_img p{
        background-color: #00000071;
    }
}

@media only screen and (max-width: 480px) {
    .TopFooterCollage .gall_img{
        height: 180px;
    }
    .TopFooterCollage .flx-2-cont{
        padding: 4rem 0;
    }
}