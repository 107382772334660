.GivingBack .inner-cont {
    padding: 76px 0px;

}

.GivingBack .inner-cont .main-title {
    color: #002833;
    font-family: Source Serif 4 14pt;
    font-size: 28px;
    text-align: center;
    font-weight: 600;
}

.GivingBack .inner-cont .main-para {
    color: #000D2A;
    font-size: 16px;
    padding: 0 3.5rem;
    font-family: Source Serif 4 14pt;
    text-align: center;
}

.GivingBack .redeem_gift_h {
    font-size: 20px;
    font-weight: 600;
}

.GivingBack .redeem_gift_p {
    font-size: 15px;
    font-weight: 600;
}

.GivingBack .inner-cont .whiteBtn {
    background-color: #fff;
    font-size: 16px;
    padding: 0.7rem 2.5rem;
    border: 0;
    border-radius: 0;
    color: #58360B;
    font-family: "Poppins-Light";
    border: 1px solid #58360b;
    /* border-color: #58360B; */
}

.GivingBack .inner-cont .btn-container {
    text-align: center;
    margin-bottom: 70px;
    margin-top: 70px;
}

.GivingBack .inner-cont .gift-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.GivingBack .inner-cont .price-tags {
    padding: 14px 115px 14px 20px;
    border: 1px solid #633e11;
    border-radius: 6px;
}

.GivingBack .inner-cont .price-tags:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.GivingBack .inner-cont .price-tags p {
    margin: 0;
}

.GivingBack .inner-cont .gift-title {
    color: #633E11;
    font-size: 34px;
    margin: 0;
    font-weight: 600;
    margin-bottom: 12px;
    font-family: 'Source Serif 4 14pt';
}

.GivingBack .inner-cont .sm-title {
    margin: 0;
    color: #002833;
    font-size: 23px;
    font-weight: 600;
    font-family: 'Source Serif 4 14pt';
    margin-bottom: 12px;
}

.GivingBack .inner-cont .para {
    margin: 0;
    color: #002833;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Source Serif 4 14pt';
}

.GivingBack .inner-cont .cart-img {
    float: right;
}

.GivingBack .inner-cont ul li {
    color: #000D2A;
    font-size: 14px;
    font-family: 'Source Serif 4 14pt';
}

.GivingBack .inner-cont input {
    width: 75%;
    border: 1px solid #D4D4D4;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.GivingBack .inner-cont textarea {
    width: 75%;
    border: 1px solid #D4D4D4;
    padding: 10px 20px;
    margin-bottom: 20px;
    height: 180px;
}

.GivingBack .inner-cont .btn-Width {
    width: 75%;
}

.GivingBack .badges-container {
    background-color: #F6F4EF;
    padding: 50px 0px;
}

.GivingBack .badges-container p {
    font-size: 15px;
    color: #633E11;
    text-align: center;
}

.GivingBack .badges-container .icon-img {
    text-align: center;
    margin-bottom: 12px;
}

.GivingBack .badges-container img:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media only screen and (max-width: 480px) {
    .GivingBack .inner-cont .main-para {
        padding: 0rem !important;
    }

    .GivingBack .m-rez-giving-back {
        margin: 1rem !important;
    }

    .GivingBack .inner-cont .gift-container {
        flex-direction: column;
    }

    .GivingBack .inner-cont .price-tags {
        margin: 1rem !important;
    }

    .GivingBack .inner-cont .cart-img {
        float: none !important;
    }

    .GivingBack .inner-cont .cart-img img {
        width: 100%;
        margin-bottom: 2rem;
    }

    .GivingBack .inner-cont .sm-title {
        font-size: 16px ;
    }

    .GivingBack .inner-cont input{
        width: 100%;
    }

    .GivingBack .inner-cont textarea{
        width: 100%;
        resize: none;    
    }
    .GivingBack .redeem_gift_h{
        margin-top: 1.5rem;
    }
}