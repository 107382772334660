.recipe-deatils{

}
.rcp-bothSide{
    margin-top: 84px;
}

.recipe-deatils .name-time-cont{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recipe-deatils .name-time-cont .title{
    font-size: 22px;
    color: #58360B;
}

.recipe-deatils .name-time-cont .time{
    font-size: 16px;
    color: #28590C;
}

.recipe-deatils .detail-card{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 35px 28px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.recipe-deatils .detail-card .name-rating{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recipe-deatils .detail-card .name-rating .name{
    color: #58360B;
    font-size: 18px;
}

.recipe-deatils .detail-card .name-rating .rating{
    color: #58360B;
    font-size: 14px;
}

.recipe-deatils .detail-card .name-rating .rating span{
    color: #D4D4D4;
    font-size: 14px;
}

.recipe-deatils .detail-card .main-para{
    color: #616161;
    font-size: 14px;
    line-height: 2;
}

.recipe-deatils .detail-card .inner-card{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 70%;
    margin: 0 auto;
    padding: 25px 66px;
}

.recipe-deatils .detail-card .inner-card .main-title{
        font-size: 14px;
        color: #58360B;
        text-align: center;
}

.recipe-deatils .detail-card .inner-card .nut-bottom{
    border-bottom: 2px solid #d3ccc2;
    margin-bottom: 15px;
}

.recipe-deatils .detail-card .inner-card .series{
    font-size: 14px;
    color: #58360B;
}

.recipe-deatils .detail-card .inner-card .num{
    font-size: 14px;
    color: #58360B;
    margin-left: 20px;
}


.recipe-deatils .detail-card .steps-cont{
    padding-bottom: 5px;
    padding-top: 68px;
}

.recipe-deatils .detail-card .steps-cont .title{
    font-size: 18px;
    color: #58360B;
}

.recipe-deatils .detail-card .steps-cont .para{
    font-size: 14px;
    color: #616161;
    line-height: 2;
}

.recipe-deatils .detail-card .steps-cont .hr-step{
    margin-bottom: 35px;
}

.recipe-deatils .main-rating-cont {
    display: flex;
    justify-content: center;
    align-items: baseline;
}