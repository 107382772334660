body {
    overflow-x: hidden;
}

.AllRoutes{
    overflow: hidden;
}

.bt-brwn {
    background-color: #58360B;
    font-size: 16px;
    padding: 0.7rem 2.5rem;
    border: 0;
    border-radius: 0;
    color: white;
    font-family: "Poppins-Light";

}

.bt-navy {
    background-color: #002533;
    font-size: 16px;
    padding: 0.7rem 2.5rem;
    border: 0;
    border-radius: 0;
    color: white;
    font-family: "Poppins-Light";

}

.lunchDinnBtmn {
    background-color: transparent;
    font-size: 22px;
    /* padding: 0.7rem 2.5rem; */
    border: unset;
    border-radius: 0;
    color: #ffffff;
    font-weight: bold;
    font-family: "Poppins-Light";
    position: absolute;
    bottom: 18px;
    left: 0;
    right: 0;
    z-index: 2;
    font-weight: 400;
}

.bt-blk {
    background-color: #00161E;
    font-size: 16px;
    padding: 0.7rem 2.5rem;
    border: 0;
    border-radius: 0;
    color: white;
    font-family: "Poppins-Light";

}

.bt-brwn-brdrrd1 {
    background-color: #58360B;
    color: white;
    font-size: 12px;
    /* width: 50%; */
    padding: 0.6rem 1.25rem;
    font-family: "Poppins-Light";

}

.bt-light-brdrrd1 {
    background-color: #fff;
    border: 1px solid #58360B;
    color: white;
    color: #58360B;
    font-weight: 500;
    font-size: 12px;
    /* width: 50%; */
    padding: 0.6rem 1rem;
    font-family: "Poppins-Light";

}

.round-5 {
    border-radius: 3px !important;
}

.round-0 {
    border-radius: 0px !important;
}

.common_bg_back {
    background-image: url("../Media/commonbg.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.pos-rel {
    position: relative;
}

.txt {
    font-size: 15px;
    /* color: #959595; */
    font-weight: 500;
    font-family: "Poppins-Regular";
}

.head {
    font-size: 24px;
    font-family: "Poppins-SemiBold";
}

.cursor_pointer {
    cursor: pointer;
}

.brw_clr {
    color: #58360B;
}

/* resposive starts */
@media only screen and (max-width: 1150px) {
    .bt-brwn-brdrrd1 {
        font-size: 12px;
        padding: 0.5rem 0.5rem;
        width: 100%;
        margin: 0.5rem 0;
    }

    .bt-light-brdrrd1 {
        font-size: 12px;
        padding: 0.5rem 0.5rem;
        width: 100%;
        margin: 0.5rem 0;
        margin-right: 0!important;
    }

    .bt-blk {
        font-size: 12px;
    }

    .res-bt-dv {
        flex-direction: column;
    }

    .bt-brwn {
        font-size: 12px;
    }

}

/* font family */

@font-face {
    font-family: "Poppins-Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins-Medium"),
        url("../Fonts/Poppins-Medium.ttf") format("woff");
}

@font-face {
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins-Regular"),
        url("../Fonts/Poppins-Regular.ttf") format("woff");
}

@font-face {
    font-family: "Poppins-Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins-Bold"),
        url("../Fonts/Poppins-Bold.ttf") format("woff");
}

@font-face {
    font-family: "Poppins-Light";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins-Light"),
        url("../Fonts/Poppins-Light.ttf") format("woff");
}

@font-face {
    font-family: "Poppins-SemiBold";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins-SemiBold"),
        url("../Fonts/Poppins-SemiBold.ttf") format("woff");
}

@font-face {
    font-family: "Source Serif 4 14pt";
    font-style: normal;
    font-weight: normal;
    src: local("Source Serif 4 14pt"),
        url("../Fonts/SourceSerif4-Regular.ttf") format("woff");
}