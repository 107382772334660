.BroadStreetMarkeet .main_heading {
    font-size: 29px;
    font-weight: 600;
    font-family: "Poppins-SemiBold";
}

.BroadStreetMarkeet .txt {
    font-size: 16px;
    font-family: "Poppins-Regular";
    color: #959595;
    padding: 0 3.5rem;
}

.BroadStreetMarkeet .box-broadstreet {
    min-height: 200vh;
}

.BroadStreetMarkeet .color-main-cont {
    background-image: linear-gradient(4deg, #fdefd573, #f9f9f957, #fdefd573);
    padding: 40px 0px;
}

.BroadStreetMarkeet .f-head-broad-zam {
    font-size: 28px !important;
    font-weight: 600 !important;
    text-align: center;
}

.BroadStreetMarkeet .color-main-cont h2 {
    color: #002833;
    text-align: center;
    font-size: 29px;
    font-weight: 600;
    font-family: 'Source Serif 4 14pt';
}

.BroadStreetMarkeet .color-main-cont .sub-heading {
    color: #002833;
    text-align: center;
    font-size: 29px;
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-family: 'Source Serif 4 14pt';
}

.BroadStreetMarkeet .color-main-cont .main-para {
    color: #818181d4;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 3rem;
    padding: 0 3.5rem;
}


.BroadStreetMarkeet .slide-bg-card {
    background-color: #fff;
    padding: 50px 4rem;
    padding-bottom: 40px;
    border-radius: 13px;
    box-shadow: 0px -4px 10px 7px #0000000f;
}

.box-broadstreet .slide-bg-card .type {
    color: #002833;
    font-size: 30px;
    font-family: 'Source Serif 4 14pt';
    margin-bottom: 20px;
}

.BroadStreetMarkeet .slider-card {
    box-shadow: rgb(0 0 0 / 5%) 2px 3px 5px 0px;
    border-radius: 5px;
    background-color: #fff;
    /* padding: 22px 20px; */
    border: 1px solid whitesmoke;
}
.BroadStreetMarkeet .slider-card img {
    width: 100%;
    height: 180px;
    border-radius: 5px;
    object-fit: inherit;
}
.BroadStreetMarkeet .slider-card .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BroadStreetMarkeet .slider-card .content .title {
    font-size: 17px;
    font-family: 'Source Serif 4 14pt';
    color: #002833;
    margin: 20px 0px;
    font-weight: 600;
    height: 50px;
}

.BroadStreetMarkeet .slider-card .content .tags {
    background-color: #DED0B6;
    padding: 4px 13px;
    font-family: 'Source Serif 4 14pt';
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.BroadStreetMarkeet .slider-card .details {
    font-size: 12px;
    font-family: 'Source Serif 4 14pt';
    color: #000;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 35px;
    padding: 0 6px;
}

.BroadStreetMarkeet .slider-card .price {
    font-size: 29px;
    color: #58360B;
    font-family: 'Source Serif 4 14pt';
    font-weight: 600;
    margin: 0;
}

.BroadStreetMarkeet .slider-card .Mealbtn {
    background-color: #58360B;
    color: #ffff;
    font-family: 'Source Serif 4 14pt';
    margin: 0;
    width: 100%;
    padding: 10px;
    margin: 10px 0px;
}

/* .BroadStreetMarkeet .slick-slide {
    margin: 0px 8px;
} */

/* .BroadStreetMarkeet .slick-track {
    height: 82vh !important;
} */

/* .BroadStreetMarkeet .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 82vh !important;
} */

.BroadStreetMarkeet .graphic-bg {
    background-image: url('../Media/graphic-bg.png');
    padding: 48px 90px;
}

.BroadStreetMarkeet .graphic-bg .main-title {
    color: #002833;
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    font-family: 'Source Serif 4 14pt';
}

.BroadStreetMarkeet .graphic-bg .type {
    width: 80px;
    background: #fff;
    margin: 0 auto;
    padding: 10px 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #58360B;
    font-size: 42px;
    font-weight: 600;

}

.BroadStreetMarkeet .graphic-bg .para {
    color: #474747;
    text-align: center;
    font-size: 18px;
    font-family: 'Source Serif 4 14pt';
}


.BroadStreetMarkeet .graphic-bg .sub-para {
    color: #002833;
    text-align: center;
    font-size: 20px;
    font-family: 'Source Serif 4 14pt';
}

.BroadStreetMarkeet .graphic-bg .sub-para span {
    color: #002833;
    text-align: center;
    font-size: 41px;
    font-weight: bold;
    font-family: 'Source Serif 4 14pt';
    margin-left: 20px;
    text-decoration: underline;
}


.BroadStreetMarkeet .graphic-bg2 {
    background-image: url('../Media/graphic-bg.png');
    padding: 48px 90px;
}

.BroadStreetMarkeet .graphic-bg2 .main-title {
    color: #002833;
    text-align: center;
    font-size: 29px;
    font-weight: 500;
    font-family: 'Source Serif 4 14pt';
}

.BroadStreetMarkeet .graphic-bg2 .type {
    width: 80px;
    background: #fff;
    margin: 0 auto;
    padding: 10px 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #58360B;
    font-size: 42px;
    font-weight: 600;

}

.BroadStreetMarkeet .graphic-bg2 .para {
    color: #474747;
    text-align: center;
    font-size: 18px;
    font-family: 'Source Serif 4 14pt';
}


.BroadStreetMarkeet .graphic-bg2 .sub-para {
    color: #002833;
    text-align: center;
    font-size: 20px;
    font-family: 'Source Serif 4 14pt';
}

.BroadStreetMarkeet .graphic-bg2 .sub-para span {
    color: #002833;
    text-align: center;
    font-size: 41px;
    font-weight: bold;
    font-family: 'Source Serif 4 14pt';
    margin-left: 20px;
    text-decoration: underline;
}


.BroadStreetMarkeet .bottom-head {
    color: #58360B;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Source Serif 4 14pt';
}

.BroadStreetMarkeet .bottom-para {
    color: #53676D;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 4rem!important;
    font-family: 'Source Serif 4 14pt';
}

.BroadStreetMarkeet .keto-right {
    background-image: url('../../Assests/Media/blurFood1.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 25px 25px;
}

.BroadStreetMarkeet .keto-right .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BroadStreetMarkeet .keto-right .header .title {
    font-size: 29px;
    font-weight: 400;
    font-family: 'Source Serif 4 14pt';
    color: #fff;
}

.BroadStreetMarkeet .keto-right .header .tag {
    font-size: 28px;
    font-weight: 400;
    font-family: 'Source Serif 4 14pt';
    color: #fff;
    background: #a39c9c;
    padding: 3px 11px;
}

.BroadStreetMarkeet .keto-right .para {
    font-size: 14px;
    font-family: 'Source Serif 4 14pt';
    color: #fff;
}

.BroadStreetMarkeet .keto-right .header2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BroadStreetMarkeet .keto-right .header2 .bld-type {
    font-size: 18px;
    font-family: 'Source Serif 4 14pt';
    color: #fff;
}

.BroadStreetMarkeet .keto-right .header2 .bld {
    font-size: 58px;
    font-family: 'Source Serif 4 14pt';
    color: #fff;
    text-decoration: underline;
}

.BroadStreetMarkeet .keto-right .multi-tags {
    display: flex;
    color: #fff;
    justify-content: space-around;
    border-radius: 3px;
    align-items: center;
    background: #878787a8;
    padding: 10px 6px;
}

.BroadStreetMarkeet .keto-right .multi-tags p {
    font-size: 16px;
    margin: 0;
}



.BroadStreetMarkeet .keto-left {
    background-image: url('../../Assests/Media/blurFood2.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 25px 25px;
}

.BroadStreetMarkeet .keto-left .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BroadStreetMarkeet .keto-left .header .title {
    font-size: 29px;
    font-weight: 400;
    font-family: 'Source Serif 4 14pt';
    color: #002833;
}

.BroadStreetMarkeet .keto-left .header .tag {
    font-size: 28px;
    font-weight: 400;
    font-family: 'Source Serif 4 14pt';
    color: #002833;
    background: #f9f1f1;
    padding: 3px 11px;
}

.BroadStreetMarkeet .keto-left .para {
    font-size: 14px;
    font-family: 'Source Serif 4 14pt';
    color: #002833;
}

.BroadStreetMarkeet .keto-left .header2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BroadStreetMarkeet .keto-left .header2 .bld-type {
    font-size: 18px;
    font-family: 'Source Serif 4 14pt';
    color: #002833;
}

.BroadStreetMarkeet .keto-left .header2 .bld {
    font-size: 58px;
    font-family: 'Source Serif 4 14pt';
    color: #002833;
    text-decoration: underline;
}

.BroadStreetMarkeet .slick-slide>div:first-child {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 1rem 0;
}

.BroadStreetMarkeet .slick-list {
    max-width: 1120px !important;
}

.BroadStreetMarkeet .box_flx_about_container {
    position: relative;
}

.BroadStreetMarkeet .Slider_top_custom_arrows-broad-prev {
    display: block;
    background-image: url('../Media/leftarrrow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    cursor: pointer;
    height: 60px;
    width: 60px;
    position: absolute;
    left: -15%;
    top: 35%;
}

.BroadStreetMarkeet .Slider_top_custom_arrows-broad-next {
    display: block;
    background-image: url('../Media/rightarrow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    cursor: pointer;
    height: 60px;
    width: 60px;
    position: absolute;
    right: -15%;
    top: 35%;
}

.BroadStreetMarkeet .noccrd {
    background-color: transparent !important;
    box-shadow: none !important;
}

.BroadStreetMarkeet .noccrd .Slider_top_custom_arrows-broad-prev {
    left: -11% !important;
}

.BroadStreetMarkeet .noccrd .Slider_top_custom_arrows-broad-next {
    right: -11% !important;
}

@media only screen and (max-width: 480px) {
    .BroadStreetMarkeet .txt{
        padding: 0rem;
    }
    .BroadStreetMarkeet .px-rez-broadstreet{
        padding: 1rem!important;
    }
    .BroadStreetMarkeet .color-main-cont .main-para{
        padding: 0rem;
    }
    .BroadStreetMarkeet .graphic-bg{
        padding: 2rem 0!important;
    }
    .BroadStreetMarkeet .slide-bg-card{
        padding: 1rem 1rem!important;
    }
    .BroadStreetMarkeet .graphic-bg .main-title{
        font-size: 14px;
    }
    .BroadStreetMarkeet .multi-tags{
        flex-direction:column ;
    }
    .BroadStreetMarkeet .keto-right .header2 .bld-type{
        font-size: 12px;
    }
    .BroadStreetMarkeet .keto-left .header .title{
        font-size: 16px;
    }
    .BroadStreetMarkeet .Slider_top_custom_arrows-broad-next{
        height: 30px;
        width: 30px;
    }
    .BroadStreetMarkeet .Slider_top_custom_arrows-broad-prev{
        height: 30px;
        width: 30px;
    }
    .BroadStreetMarkeet .color-main-cont .Slider_top_custom_arrows-broad-prev{
        left: -9px;
    }
    .BroadStreetMarkeet .color-main-cont .Slider_top_custom_arrows-broad-next{
        right: -4px;
    }
    .BroadStreetMarkeet .f-head-broad-zam{
        margin-top: 1rem!important;
    }
}