.Auth .Auth_box {
    background-color: #F8F8F8;
    padding: 1.3rem;
    padding-top: 2rem;
    box-shadow: 0px 3px 6px 1px #00000029;
    margin: 4rem 0;
}

.Auth .main_heading {
    font-size: 24px;
    font-family: "Poppins-SemiBold";
}

.Auth .main_sub {
    font-size: 12px;
    font-family: "Poppins-Medium";
}

.Auth .main-px-mx {
    padding: 0 4rem;
}

.Auth .input_fld {
    background-color: #fff;
    padding: 1rem 1.3rem;
    border: 1px solid #CFCFCF;
    width: 100%;
    margin: 0.5rem 0;
    border-radius: 2px;
}

.Auth .input_fld:focus {
    outline: none;
}

.Auth .eye_set {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    color: grey;
}

.Auth .dont {
    color: #959595;
    font-size: 12px;
    font-family: "Poppins-Regular";
}

.Auth .brwnclr {
    color: #58360B;
    font-size: 14px;
}

.Auth .line-main {
    display: flex;
}

.Auth .line1 {
    border-bottom: 1px dashed #707070;
    flex: 3 1;
}

.Auth .or {
    /* color: #9b9b9b; */
    display: flex;
    flex: 1 1;
    /* font-family: Lato-Regular; */
    font-size: 20px;
    justify-content: center;
    position: relative;
    top: 11px;
}

.Auth .line2 {
    border-bottom: 1px dashed #707070;
    flex: 3 1;
}

.Auth .btn-goog {
    border: 0;
    border-radius: 0;
    width: 100%;
    /* color: violet; */
    background: #4286F5;
    color: white;
    padding: 1rem;
    font-family: "Poppins-Medium";

}

.Auth .img_goog {
    position: absolute;
    top: 29%;
    left: 29%;
}


.Auth .btn-fb {
    border: 0;
    border-radius: 0;
    width: 100%;
    /* color: violet; */
    background: #3B5999;
    color: white;
    padding: 1rem;
    font-family: "Poppins-Medium";
}

.Auth .img_fb {
    position: absolute;
    top: 29%;
    left: 27%;
}

.Auth .btn-appl {
    border: 0;
    border-radius: 0;
    width: 100%;
    /* color: violet; */
    background: #001721;
    color: white;
    padding: 1rem;
    font-family: "Poppins-Medium";
}

.Auth .img_appl {
    position: absolute;
    top: 29%;
    left: 29%;
    color: white;
}

@media only screen and (max-width: 1150px) {
    .Auth .img_goog {
        left: 19%;
    }

    .Auth .img_fb {
        left: 19%;
    }

    .Auth .img_appl {
        left: 19%;
    }
}