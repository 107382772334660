.Your_Power_content .maindiv_lambiline {
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    position: relative;
}

.Your_Power_content .lambi_line {
    border-bottom: 3px solid #7e7f80;
    position: absolute;
    top: 35%;
    width: 70%;
    z-index: -1;
    left: 15%;
}

.Your_Power_content .flx_stepsboxes {
    display: flex;
    justify-content: space-between;
}

.Your_Power_content .bx-active {
    font-size: 12px;
    background: #58360B;
    border: 1px solid #58360B;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 0.5rem;
    padding: 0.4rem 0.6rem;
    /* text-align: center; */
}

.Your_Power_content .box-wdth {
    width: 100px;
    text-align: center;
}

.Your_Power_content .bx-noactive {
    font-size: 12px;
    background: #a7a8a9;
    border: 1px solid #a7a8a9;
    border-radius: 50%;
    color: #00000070;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 0.5rem;
    padding: 0.4rem 0.6rem;
    /* text-align: center; */
}

.Your_Power_content .bx-active .step1 {
    color: #58360B;
}

.Your_Power_content .step1 {
    font-size: 12px;
    color: #5D5D5D;
    font-weight: 600;
    text-align: center;
}

.Your_Power_content .inpt-fld {
    background-color: #F8F8F8;
    color: #000;
    border: 0;
    border-radius: 0;
    font-size: 14px;
    padding: 0.9rem 1.4rem;
    width: 100%;
    resize: none;
}

.Your_Power_content .inpt-fld::placeholder {
    color: #000;
    font-size: 14px;
}

.Your_Power_content .inpt-fld:focus {
    outline: none;
}

.Your_Power_content .brw_clr {
    color: #58360B;
    font-size: 13px;
}


/* your power 3 */

.Your_Power_content .power3-next {
    background-image: url('../Media/Power/right-pow.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 24px;
    width: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: -30px;
}

.Your_Power_content .power3-prev {
    background-image: url('../Media/Power/left_pow.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 24px;
    width: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: -55px;
}

.Your_Power_content .actv_brwn {
    background-color: #58360B;
    color: white;
    font-size: 13px;
    /* align-items: center; */
    max-width: 85px;
    text-align: center;
    padding-top: 1rem;
}


.Your_Power_content .noactv_brwn {
    background-color: #F8F8F8;
    color: #58360B;
    font-size: 13px;
    /* align-items: center; */
    max-width: 85px;
    text-align: center;
    padding-top: 1rem;
}

.Your_Power_content .gry_sec_power {
    background: #F8F8F8;
    padding: 4rem 0;
    margin-top: 3rem;
}

.Your_Power_content .menu_for_txt {
    color: #58360B;
    font-size: 14px;
    font-weight: 500;
}

.Your_Power_content .menu_for_box {
    color: #58360B;
    font-size: 22px;
    font-weight: 600;
}

.Your_Power_content .line_gry_light {
    border-bottom: 1px solid lightgray;
    margin-top: 1rem;
}

.Your_Power_content .img_powm {
    width: 80%;
}

.Your_Power_content .w-btn-pow {
    width: 90%;
    font-weight: 600;
    border: 2px solid #58360B;
    border-radius: 0;
}


.Your_Power_content .del_btn_pow {
    width: 90%;
    font-weight: 600;
    border-radius: 0;
    margin: auto;
    font-size: 12px;
    cursor: pointer;
}

.Your_Power_content .del_ico {
    padding: 0.6rem;
    border: 2px solid;
    padding-bottom: 0.75rem;
    color: #57350e;
    background-color: #fff;
    position: relative;
    top: 0px;
}

.Your_Power_content .py-cus-poww {
    padding: 0.6rem 5.4rem !important;
    font-size: 14px;
}

.Your_Power_content .show_other_pow1 {
    font-size: 14px;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.Your_Power_content .top_heading_crd {
    font-size: 22px;
    color: #707070;
    font-weight: 600;
}

.Your_Power_content .top_cont_crd {
    font-size: 12px;
    color: #707070;
    font-weight: 400;
    margin-bottom: 0;
    height: 35px;
    text-overflow: ellipsis;
}

/* checkout css */

.Your_Power_content .crd_add_home_address_box {
    padding: 3rem 2rem;
    border-radius: 12px;
    border: 1px solid #E7E7E7;
    box-shadow: 1px 4px 7px #00000026;
    margin-bottom: 3rem;
}

.Your_Power_content .crd_add_home_address_had {
    font-size: 24px;
    font-weight: 600;
}

.Your_Power_content .bg-trans {
    background-color: #FCFCFC;
}

.Your_Power_content .crd_add_home_address_loretxt {
    color: #959595;
    font-size: 12px;
}

.Your_Power_content .Order_summary_box {
    box-shadow: 1px 4px 7px #00000026;
    background-color: white;
    border-radius: 4px;
    padding: 1.5rem 1.3rem;
}

.Your_Power_content .Order_summary_box_p {
    font-size: 14px;
    font-weight: 700;
}

.Your_Power_content .gr_bx_t {
    color: #464646ba;
}

.Your_Power_content .brdr-btm-w100 {
    border-bottom: 1px solid #46464659;
    margin: 1rem 0;
    margin-top: 0;
    width: 100%;
}

.Your_Power_content .your_p_Asses {
    font-size: 30px;
    margin-bottom: 2rem;
    font-weight: 600;
}

@media only screen and (max-width: 1150px) {
    .Your_Power_content .step1 {
        font-size: 10px;
    }

    .Your_Power_content .lambi_line {
        top: 39%;
    }

    .Your_Power_content .del_ico {
        padding: 0.6rem;
        border: 2px solid;
        padding-bottom: 0.75rem;
        color: #57350e;
        background-color: #fff;
        position: relative;
        top: 32px;
        left: -98px;
    }
}

@media only screen and (max-width: 480px){
    .Your_Power_content .step1 {
        font-size: 9px;
    }
}
