/* full header starts*/
.Header {
    background-color: #f2f2f273;
}

.Header .bg-tp-hdr {
    padding: 1.5rem 0;
    box-shadow: 0px 0px 9px #00000047;
}

.Header .justify-center {
    justify-content: left;
}

.Header .bg-btm-hdr {
    padding: 1.5rem 0;
    padding-bottom: 0.8rem;
    background-color: #F2F2F2;
}

.Header .brownclr {
    color: #58360B;
    font-weight: 600;
}

.Header .brownclr:hover{
    text-decoration: underline;
    cursor: pointer;
}

.Header .header_search_fld {
    width: 70%;
    border: 0;
    background-color: #ECEEEF;
    padding: 0.8rem 1.5rem;
}

.Header .header_search_fld:focus {
    outline: none;
}

.Header .brwn_bx {
    background: #58360B;
    color: white;
    align-self: center;
    padding: 0.8rem 1rem;
}

.Header .ico_div_box {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.Header .input-div-bx {
    padding-top: 2rem;
}

.Header .brdr-right-line {
    border-right: 1px solid #707070;
    /* width: 20px; */
    height: 1.4rem;
    margin: 0rem 2rem 0 2rem;
}

.Header .linksection_div {
    display: flex;
    justify-content: space-between;
}

.Header .links_divs {
    display: flex;
}

.Header .link_single_div {
    display: flex;
}
.Header .link_single_div .active{
    color:  #58360B;
}

.Header .dot_headerzz_last{
    right: -10px!important;
}

.Header .dot_headerzz {
    position: absolute;
    top: -77px;
    right: 40px;
    font-size: 70px;
    color: #58360b;
}

.Header .header_p {
    font-size: 15px;
    margin: 0;
    color: grey;
    font-family: "Poppins-Regular";
}

.Header .icons_divs {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -3px;
}

/* .Header .brownclr {
    font-family: "Poppins-SemiBold";
} */
    /* cursor: pointer;
} */

/* full header endss*/

/* image header inner */
/* .HeaderInner {
    background-image: url('../Media/about/aboutBanner.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 30.1rem 0;
    color: #fff;
} */
.HeaderInner {
    background:linear-gradient(176deg, black, transparent), url('../Media/about/aboutBanner.webp');
    background-position: 100% 60%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 4.1rem 0;
}
/* .HeaderInner_h1 {
    font-size: 60px;
    font-family: "Poppins-SemiBold";
    margin-bottom: 0;
    font-weight: 600;
    padding-top: 18rem;
} */
.HeaderInner_h1 {
    font-family: Poppins-SemiBold;
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 0;
    padding-top: 0;
    color: #fff;
    /* background: #fff; */
    width: fit-content;
    margin: auto;
    /* padding-top: 11rem; */
    text-align: center;
    padding: 0 10px;
}

/* image header inner */

/* responsive set */
/* @media(max-width:1440px){
    .HeaderInner{
        padding: 22.1rem 0;
    }
    .HeaderInner_h1{
        
    }
}
@media(max-width:1366px){
    .HeaderInner{
        padding: 20.1rem 0;
    }
}
@media(max-width:1280px){
    .HeaderInner {
        padding: 18.1rem 0;
    }
}
@media(max-width:1200px){
    .HeaderInner {
        padding: 17.1rem 0;
    }
}
@media(max-width:1100px){
    .HeaderInner {
        padding: 14.1rem 0;
    }
}
@media only screen and (max-width: 1150px){
    .Header .header_p{
        font-size: 12px;
    }
    .Header .brownclr{
        font-size: 14px;
    }
} */

/* responsive banner */



/* // second header for responsive starts*/

.header-small {
    display: none;
}

.swip_header_sml {
    display: none;
}

@media only screen and (max-width: 992px) {
    .bg-red-res-hdr {
        background-color: #fff;
        color: black;
        margin-top: 2px;
        margin-right: 6px;
    }

    .header-full {
        display: none;
    }

    .swip_header_sml {
        display: block;
    }

    .header-small {
        display: block;
    }

    .header .flx-header-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .header-small .img_respon {
        height: 40px;
        padding: 5px 5px !important;
        background-color: #fff !important;
        /* width: 30px; */
    }

    .header-small .line-small-hdr {
        margin-left: 10px;
    }

    .header-small .bg-red-hdr {
        background-color: #58360B;
        color: white;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0.5rem;
    }

    .header-small .align-center {
        align-self: center;
    }

    .clr-hom-sml-hdr {
        color: #58360B;
        text-decoration: none;
    }

    .clr-hom-sml-hdr :hover {
        color: #202076 !important;
        text-decoration: none;
        /* border-bottom: 1px solid #df1921; */
        /* padding-bottom: 0.5rem; */
    }

    .list-style-type-circle {
        list-style-type: circle;
    }

    .flx-1 {
        flex: 1;
    }

    .flx-2 {
        flex: 3.5;
    }

    .flex-end-hdr-smll {
        justify-content: flex-end;
        cursor: pointer;
        color: #DF1921;
    }

    .flex-end-hdr-smll:hover {
        color: #202076;
    }

    .close_btn_sml_hdr {
        float: right;
        margin-right: 1rem;
    }

    .MuiDrawer-root>.MuiPaper-root {
        border-radius: 0px 0px 8px 8px;
    }

    .swip_header_sml .css-1ytz6dp {
        background-color: #58360B;
    }

    .pos-rel-top-four {
        position: relative;
        top: 3px;
        left: 12px;
        font-size: 12px;
    }

    .content_control_swiphead_res_header {
        width: 100vw;
        height: 100%;
        padding: 10px 30px;
    }
    .flx_swipheader_res{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 1.5rem;
    }
}


/* // second header for responsive ends*/
